.readingSessionItem {
  margin-inline-end: var(--spacing-xsmall);
  white-space: nowrap;
}

.linkButtonContainer {
  font-weight: var(--font-weight-semibold);
  // text-decoration: underline;
  background: #02989a;
  color: white;
  padding-left: 4px;
  padding-right: 4px;
}
